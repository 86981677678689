import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W007 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 007: Relays, Part 1 of (2)" />
    <div className="container container-last">
      <h1>Webinar 007: Relays, Part 1 of (2)</h1>
      <hr />
      <p class="boost">
        "What They Never Told You About Mechanical and Solid-State Relays That You Need to Know."
      </p>
      <p>
        In this webinar we discuss mechanical relay operation and common mistakes made due to lack
        of proper training. How mechanical relays work and procedures to protect computer circuits
        from potential damage caused by mechanical relays is explained. Learn what to look for first
        in a schematic diagram of a mechanical relay circuit as we discuss relays in schematic
        diagrams.
      </p>
      <p>
        In this webinar you will learn how to check a spike suppression diode. Learn what to check
        first if a mechanical relay clicks. Learn what to check first if a mechanical relay DOES NOT
        click? Now that Solid-State Relays (SSR) are becoming more common in vehicle electrical
        systems replacing mechanical relays, we explain why, how they work and how they fail.
      </p>
      <p>
        This webinar prepares technicians for the next in-depth Webinar W-008, Troubleshooting
        Procedures of Mechanical and Solid-State Relays" with detailed voltage and resistance test
        readings.
      </p>
      <ul style={{ marginBottom: 0 }}>
        <li>Watch Webinar W007 Session</li>
        <li>
          Purchase a link to download the Webinar W007 recording and watch as often as you like.
          (Download link expires after 7 days)
        </li>
      </ul>
      <Button
        type="secondary"
        text="BUY NOW for $39.00"
        href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=c92eca7799ad4aefab6c845bd642fa08"
      />

      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W007
